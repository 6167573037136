<template>
<v-app id="app" :style="{background: $vuetify.theme.themes[isDark].background}">
	<v-main>
		<v-container v-if="dataReady" class="fill-height" fluid>
			<v-row align="center" justify="center">
				<v-col cols="12" xs="10" sm="9" md="7" lg="5" xl="5">
					<router-view></router-view>
				</v-col>
			</v-row>
		</v-container>
	</v-main>
</v-app>
</template>

<!--
<style>
  .login-form {
      max-width: 500px;
  }
</style>
-->

<script>
import Config from "@/config"
import Api from "@/lib/api"
import store from "@/store"

export default {
    name: "App",
    computed: {
        isDark() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        },
    },
    methods: {
    },
    data: () => ({
			dataReady: false
		}),
    mounted() {
			~(async ()=>{
				try {
					let {data} = await Api.AppInfo()
					if (data.errCode == 0) {
						store.commit('setAppInfo', data.appInfo)

						let script = document.createElement('script')
						script.setAttribute('src', 'https://telegram.org/js/telegram-widget.js?15')
						script.setAttribute('data-telegram-login', data.appInfo.tgBotName)
						script.setAttribute('data-size', 'large')
						script.setAttribute('data-auth-url', '/auth/telegram/callback')
						document.head.appendChild(script)

						this.dataReady = true
					} else {
						this.$dialog.notify.error(data.errMsg, Config.notifySetting)
					}
				} catch (err) {
					console.log(err)
				}
			})()
    },

};
</script>
