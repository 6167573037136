<template>
  <v-card class="rounded-lg elevation-12" rounded>
    <v-toolbar color="primary" height="56" dark flat>
      <h3>User Information</h3>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row class="my-0" align="center" justify="center">
          <v-avatar size="96">
            <v-img :src="pictureURL"></v-img>
          </v-avatar>
        </v-row>
        <v-row class="my-1" align="center" justify="center">
          <h2>{{info.FullName}}</h2>
        </v-row>
        <v-row class="my-0" align="center" justify="center">
          {{info.UserName}}
        </v-row>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-list>
      <v-list-item v-for="(item, i) in mitems" :key="i" @click="doMenuClick(item)">
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </v-card>
</template>

<script>

import Config from "@/config"
import Api from "@/lib/api"
import store from "@/store"
import router from "@/router"

export default {
  methods: {
    async doMenuClick(item) {
      if (item.link) {
        router.replace(item.link)
      } else {
        let res = await this.$dialog.confirm({
          text: 'Do you really want to logout?',
          title: 'Confirm'
        })
        if (res) {
          store.dispatch('Logout')
          let s = encodeURIComponent('/#/callback/')
          window.location.href = `/auth/logout/?ReturnURL=${s}`
        }
      }
    },
  },
  computed: {
    pictureURL() {
      let imgurl = this.info.Picture ? this.info.Picture : require("@/assets/avatardefault.png")
      return imgurl
    },
  },
  data: () => ({
    info: {
      FullName: '',
      UserName: '',
      Picture: '',  
    },
    mitems: [
      {id: 1, text:'Profile Settings',    icon: 'mdi-cog-outline',  link:'/profile'},
      {id: 2, text:'Device Verification', icon: 'mdi-devices',      link:'/deviceverification'},
      {id: 3, text:'Social Accounts',     icon: 'mdi-link',         link:'/sociallinks'},
      {id: 4, text:'Change Password',     icon: 'mdi-lock',         link:'/changepass'},
      {id: 5, text:'Logout',              icon: 'mdi-logout',       link:null},
    ],
  }),
  mounted() {
    Api.GetProfile().then((res)=>{
      let data = res.data
      if (data.errCode == 0) {
        this.info = data.userProfile
      } else {
        this.$dialog.notify.error(data.errMsg, Config.notifySetting)

        store.dispatch('Logout')
        let s = encodeURIComponent('/#/callback/')
        window.location.href = `/auth/logout/?ReturnURL=${s}`
      }
    }).catch(err=>{
      console.log(err)
    }) 
  },
}
</script>