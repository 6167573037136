<template>
  <div>
    <v-card class="rounded-lg elevation-12" rounded>
      <v-toolbar color="primary" height="56" dark flat>
        <h3>Welcome to {{app.title}} Platform</h3>
      </v-toolbar>

      <v-row class="mx-0" align="center">
        <template v-if="device.showPin">
          <v-col cols="7">
            <v-card-text>
              <v-form ref="Form" v-model="formSet.valid" lazy-validation>
                <v-text-field v-model="info.username" light prepend-icon="mdi-email" label="Email" type="email" :rules="emailRules" required></v-text-field>
                <v-text-field v-model="info.password" light prepend-icon="mdi-lock" label="Password" :type="formSet.showPass ? 'text' : 'password'" :append-icon="formSet.showPass ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required]" hint="At least 6 characters" counter  @click:append="formSet.showPass = !formSet.showPass"></v-text-field>
                <v-checkbox v-model="rememberMe" light label="Keep me signed in" hide-details="hide-details"></v-checkbox>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-container>
                <v-row class="mx-0" align="center">
                  <v-btn color="success" block rounded type="submit" :disabled="!formSet.valid" @click.prevent="doLogin">Login</v-btn>
                </v-row>

                <v-row class="my-3" align="center">
                  <v-divider></v-divider>
                  <span class="blue-grey--text text--lighten-1">OR</span>
                  <v-divider></v-divider>
                </v-row>

                <v-row class="my-3" align="center" justify="center">
                  <v-btn class="mx-2" color="#dd4b39" fab dark small @click.prevent="doSocialLogin('Google')">
                    <v-icon dark>mdi-google</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" color="#3B5998" fab dark small @click.prevent="doSocialLogin('Facebook')">
                    <v-icon dark>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" color="#1DA1F2" fab dark small @click.prevent="doSocialLogin('Twitter')">
                    <v-icon dark>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" color="#0088CC" fab dark small @click.prevent="doSocialLogin('Telegram')">
                    <v-icon dark>mdi-telegram</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-col>
          <v-col>
            <v-row class="my-3" align="center" justify="center">
              <span class="blue-grey--text"><B>Sign in with QR Code</B></span>
            </v-row>
            <v-row class="my-3" align="center" justify="center">
              <qr-code :text="device.pinCode" size="180" error-level="L"></qr-code>
            </v-row>
            <v-row class="my-3" align="center" justify="center">
              <span class="blue-grey--text"><B>PIN code:&nbsp;</B></span>
              <h3>{{device.pinCode}}</h3>
            </v-row>
          </v-col>
        </template>
        <template v-if="!device.showPin">
          <v-col>
            <v-card-text>
              <v-form ref="Form" v-model="formSet.valid" lazy-validation>
                <v-text-field v-model="info.username" light prepend-icon="mdi-email" label="Email" type="email" :rules="emailRules" required></v-text-field>
                <v-text-field v-model="info.password" light prepend-icon="mdi-lock" label="Password" :type="formSet.showPass ? 'text' : 'password'" :append-icon="formSet.showPass ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required]" hint="At least 6 characters" counter  @click:append="formSet.showPass = !formSet.showPass"></v-text-field>
                <v-checkbox v-model="rememberMe" light label="Keep me signed in" hide-details="hide-details"></v-checkbox>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-container>
                <v-row class="mx-0" align="center">
                  <v-btn color="success" block rounded type="submit" :disabled="!formSet.valid" @click.prevent="doLogin">Login</v-btn>
                </v-row>

                <v-row class="my-3" align="center">
                  <v-divider></v-divider>
                  <span class="blue-grey--text text--lighten-1">OR</span>
                  <v-divider></v-divider>
                </v-row>

                <v-row class="my-3" align="center" justify="center">
                  <v-btn class="mx-2" color="#dd4b39" fab dark small @click.prevent="doSocialLogin('Google')">
                    <v-icon dark>mdi-google</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" color="#3B5998" fab dark small @click.prevent="doSocialLogin('Facebook')">
                    <v-icon dark>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" color="#1DA1F2" fab dark small @click.prevent="doSocialLogin('Twitter')">
                    <v-icon dark>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" color="#0088CC" fab dark small @click.prevent="doSocialLogin('Telegram')">
                    <v-icon dark>mdi-telegram</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-col>
        </template>
      </v-row>
   

    </v-card>


    <v-content class="my-4">
      <v-row class="my-1" align="center" justify="center">
        <span class="wl">
          <h4>
            <router-link to="/signup" tag="wl">Register now</router-link> | 
            <router-link to="/forgot" tag="wl">Forgot password?</router-link>
          </h4>
          <v-idle class="bk" @idle="doIdle" :duration="10" />  
        </span>
      </v-row>
    </v-content>
  </div>
</template>

<style scoped>
  .wl, wl {
    color: #ffffff;
    cursor: pointer;
  }
  .bk {
    color: #1976D2;
  }

  wl:hover {
    text-decoration: underline;
  }
</style>

<script>

import Config from "@/config"
import Api from "@/lib/api"
import Lib from "@/lib"
import router from "@/router"
import store from "@/store"
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  components:{
    'qr-code': VueQRCodeComponent,
  },
  methods: {
    doAfterLogin(data) {
      if (data.userInfo)
        store.commit('setUserInfo', data.userInfo)
      if (data.returnurl)
        store.commit('setReturnURL', data.returnurl)

      let ReturnURL = store.getters.ReturnURL
      if (ReturnURL) {
        window.location.href = Lib.urlWithQueryString(ReturnURL,{data})
      } else {
        router.replace('/settings')
      }
    },
    doLogin() {
      ~(async () => {
        if (this.$refs.Form.validate()) {
          try {
            let {data} = await Api.Login({
              UserName: this.info.username, 
              Password: this.info.password,
              Remember: store.getters.RememberMe,
            })
            if (data.errCode == 0) {
              this.doAfterLogin(data)
            } else {
              this.$dialog.notify.error(data.errMsg, Config.notifySetting)
            }
          } catch (err) {
            console.log(err)
          }
        }

      })()
    },

    doSocialLogin(aType) {
      if (aType!='Telegram') {
        window.location.href = Lib.urlWithQueryString('/auth/social/login/',
          {AccType:aType}
        )
      } else {
        window.Telegram.Login.auth(
          { bot_id: this.app.tgBotID, request_access: true },
          (profile) => {
            if (profile) {
              let ExtInfo = JSON.stringify(profile)
              window.location.href = Lib.urlWithQueryString('/auth/social/login/',
                {AccType:'Telegram', ExtInfo}
              )
            }
          }
        )
      }
    },

    doIdle() {
      this.device.showPin = true
    },
  },
  computed: {
    rememberMe: {
      get() {
        return store.getters.RememberMe
      },
      set(value) {
        store.commit('setRememberMe', value)
      }
    }
  },
  data: () => ({
    app: {
      title: "YPCLoud",
      baseURL: "",
      tgBotID: "",
      tgBotName: "",
    },
    formSet: {
      valid: false,
      showPass: false,
    },
    info: {
      username: "",
      password: "",
    },        
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 6 || 'Min 6 characters',
    },
    device: {
      showPin: false,
      pinCode: '12345678',
    },
    es: undefined,
    eshandler: undefined,
  }),
  mounted() {
    this.app = store.getters.AppInfo
    Lib.openSocket(this.app.baseURL+'/auth/socket', async (msg)=>{
      try {
        if (msg.action=='setpin') {
          this.device.pinCode = msg.pinCode.toString()
        } else if (msg.action=='otac') {
          let {data} = await Api.LoginOTAC({
            OtacToken: msg.OtacToken,
          })
          if (data.errCode == 0) {
            this.doAfterLogin(data)
          } else {
            this.$dialog.notify.error(data.errMsg, Config.notifySetting)
          }
        }
      } catch (err) {
        console.log(err)
      }
    })

/*
    let self = this
    setTimeout(()=>{
      console.log('Time up!')
      self.device.showPin = true
    },5000)
*/
  },
  beforeDestroy() {
    Lib.closeSocket()
  },
}
</script>