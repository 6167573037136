<template>
  <div>
    <v-card class="rounded-lg elevation-12" rounded>
      <v-toolbar color="primary" height="56" dark flat>
        <h3>Forget Password</h3>
      </v-toolbar>

      <v-card-text>
        <v-form ref="Form" v-model="formSet.valid" lazy-validation>
          <v-text-field v-model="info.username" light prepend-icon="mdi-email" label="Email" type="email" :rules="emailRules" required></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-row class="mx-0" align="center">
            <v-btn color="success" block rounded :disabled="!formSet.valid" @click.prevent="doSendMail">email me a recovery link</v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>


    <v-content class="my-4">
      <v-row class="my-1" align="center" justify="center">
        <span class="wl">
          <h4>
            <router-link to="/login" tag="wl">Go back to login</router-link>
          </h4>
        </span>
      </v-row>
    </v-content>
  </div>
</template>

<style scoped>
  .wl, wl {
    color: #ffffff;
    cursor: pointer;
  }

  wl:hover {
    text-decoration: underline;
  }
</style>

<script>

import Config from "@/config"
import Api from "@/lib/api"
import router from "@/router"

export default {
  methods: {
    doSendMail() {
      ~(async () => {
        if (this.$refs.Form.validate()) {
          try {
            let {data} = await Api.Forgot({
              UserName: this.info.username,
            })

            if (data.errCode==0) {
              await this.$dialog.info({
                text: 'An email has been sent. Please click the link when you get it.',
                title: 'Info'
              })
              router.replace('/')
            } else {
              this.$dialog.notify.error(data.errMsg, Config.notifySetting)
            }
          } catch (err) {
            console.log(err)
          }
        }
      })()
    }
  },
  data: () => ({
    formSet: {
      valid: true,
    },
    info: {
      username: "",
    },        
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 6 || 'Min 6 characters',
    },
  }),
}
</script>