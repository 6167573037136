import { render, staticRenderFns } from "./ShowMsg.vue?vue&type=template&id=540a2194"
import script from "./ShowMsg.vue?vue&type=script&lang=js"
export * from "./ShowMsg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports