<template>
  <div>
    <v-card class="rounded-lg elevation-12" rounded>
      <v-toolbar color="primary" height="56" dark flat>
        <h3>Change password</h3>
      </v-toolbar>

      <v-card-text>
        <v-form ref="Form" v-model="formSet.valid" lazy-validation>
          <v-text-field v-model="info.newpass" light prepend-icon="mdi-lock" label="New Password" :type="formSet.showPass ? 'text' : 'password'" :append-icon="formSet.showPass ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" hint="At least 6 characters" counter @click:append="formSet.showPass = !formSet.showPass"></v-text-field>
          <v-text-field v-model="info.confirm" light prepend-icon="mdi-lock" label="Retype Password" :type="formSet.showPass ? 'text' : 'password'" :append-icon="formSet.showPass ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, passConfirmRule]" hint="At least 6 characters" counter @click:append="formSet.showPass = !formSet.showPass"></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-row class="mx-0 py-2" align="center">
            <v-btn color="success" block rounded type="submit" :disabled="!formSet.valid" @click.prevent="doChange">Change Password</v-btn>
          </v-row>
          <v-row class="mx-0 py-2" align="center">
            <v-btn block rounded to="/settings">Cancel</v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style scoped>
  .wl, wl {
    color: #ffffff;
    cursor: pointer;
  }

  wl:hover {
    text-decoration: underline;
  }
</style>

<script>

import Config from "@/config"
import Api from "@/lib/api"
import router from "@/router"
import store from "@/store"

export default {
  methods: {
    doChange() {
      ~(async () => {
        if (this.$refs.Form.validate()) {
          try {
            let OpToken = store.getters.OpToken
            let {data} = await Api.ChangePass({
              OpToken,
              NewPass: this.info.newpass,
            })
            if (data.errCode==0) {
              await this.$dialog.info({
                text: 'Your Password has been changed.',
                title: 'Info'
              })
              router.replace('/settings')
            } else {
              this.$dialog.notify.error(data.errMsg, Config.notifySetting)
            }
          } catch (err) {
            console.log(err)
          }
        }
      })()
    },
  },
  computed: {
    passConfirmRule() {
      return () => (this.info.newpass === this.info.confirm) || 'Passwords must match.'
    },
  },
  data: () => ({
    formSet: {
      valid: true,
      showPass: false,
    },
    info: {
      newpass: "",
      confirm: "",
    },        
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 6 || 'Min 6 characters',
    },
  }),
}
</script>