<template>
  <v-card class="rounded-lg elevation-12" rounded>
    <v-toolbar color="primary" height="56" dark flat>
      <h3>Message</h3>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <h2>{{ message }}</h2>
      </v-container>
    </v-card-text>

  </v-card>
</template>

<script>

import store from '../store'

export default {
  computed: {
    message() {
      let lastMsg = store.getters.LastMsg
      return lastMsg.errMsg
    },
  },
}
</script>