import axios from 'axios'
import store from '../store'
import Config from '../config'

const axiosInst = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: Config.apiBaseURL,
})


axiosInst.interceptors.request.use(
  config => {
    if (store.getters.isAuthenticated)
      config.headers['Authorization'] = 'Bearer ' + store.getters.AccessToken
    return config
  }
)


const Api = {
  AppInfo: () => axiosInst.get('/appinfo'),
  Signup: data => axiosInst.post('/signup', data),
  Login: data => axiosInst.post('/login', data),
  LoginOTAC: data => axiosInst.post('/login/otac', data),
  Forgot: data => axiosInst.post('/forgot', data),
  Reset: data => axiosInst.post('/reset', data),
  PermissionOpToken: data => axiosInst.post('/permission/optoken', data),
  PermissionOTAC: data => axiosInst.post('/permission/otac', data),
  ChangePass: data => axiosInst.post('/changepass', data),
  GetProfile: () => axiosInst.get('/profile'),
  SetProfile: data => axiosInst.post('/profile', data),
  SetProfileAvatar: data => axiosInst.post('/profile/avatar', data),
  SocialInfo: () => axiosInst.get('/social/info'),
  SocialUnbind: data => axiosInst.post('/social/unbind',data),
}

export default Api
