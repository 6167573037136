<template>
  <div>
    <v-card class="rounded-lg elevation-12" rounded>
      <v-toolbar color="primary" height="56" dark flat>
        <h3>Device Verification</h3>
      </v-toolbar>

      <v-card-text>
        <v-row class="my-0" align="center" justify="center">
          <!--
          <p class="decode-result">Last result: <b>{{ result }}</b></p>
          -->
          <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
            <div class="scan-info" v-if="showLoading || showConfirm || showError">
              <img src="@/assets/checkmark.svg" alt="Checkmark" width="128px" v-if="showConfirm"/>
              <img src="@/assets/camera.svg" alt="camera" width="128px" v-if="showError"/>
              <v-progress-circular
                :size="100"
                :width="15"
                indeterminate
                color="green"
                v-if="showLoading">
              </v-progress-circular>            
            </div>

          </qrcode-stream>
          <h3 class="red--text">{{ error }}</h3>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-row class="mx-0 py-0" align="center" justify="center">
            <v-col>
              <v-form ref="Form" v-model="formSet.valid" lazy-validation>
              <v-text-field
                v-model="pinCode"
                light prepend-icon="mdi-email"
                clearable
                label="PIN code"
                type="text"
                :rules="pinRules"
                required>
              </v-text-field>
              </v-form>
            </v-col>
          </v-row>

          <v-row class="mx-0 py-2" align="center">
            <v-btn color="success" block rounded type="submit" :disabled="!formSet.valid" @click.prevent="doVerify">Verify</v-btn>
          </v-row>
          <v-row class="mx-0 py-2" align="center">
            <v-btn block rounded to="/settings">Cancel</v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>


<script>

import Config from "@/config"
import Api from "@/lib/api"
import router from "@/router"
import store from "@/store"
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream,
  },
  methods: {
    async doVerify() {
      try {
        if (this.$refs.Form.validate()) {
          let OpToken = store.getters.OpToken
          let {data} = await Api.PermissionOTAC({
            OpToken,
            PinCode: this.pinCode,
          })
          if (data.errCode==0) {
            await this.$dialog.info({
              text: 'Verification successfully.',
              title: 'Info'
            })
            router.replace('/settings')
          } else {
            this.$dialog.notify.error(`pinCode:${data.errMsg}`, Config.notifySetting)
          }
        }
      } catch (err) {
        console.log(err)
      }
    },

    async onDecode (content) {
      this.pinCode = content;
      this.showConfirm = true
      this.pause()
      await this.doVerify()
      //await this.timeout(500)
      this.showConfirm = false
      this.unpause()
    },

    async onInit (promise) {
      try {
        this.showLoading = true
        await promise
      } catch (error) {
        this.showError = true
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: You need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: No camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: Secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: Is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: Installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      } finally {
        this.showLoading = false
      }
    },

    unpause () {
      this.camera = 'auto'
    },

    pause () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },

  },
  data: () => ({
    camera: 'auto',
    //result: '',
    error: '',
    showLoading: false,
    showConfirm: false,
    showError: false,
    pinCode:'',
    formSet: {
      valid: false,
    },
    pinRules: [
      v => !!v || 'Pin code is required',
      v => /^\d+$/.test(v) || 'Invalid pin code',
      v => v.length == 6 || '6 digit characters',
    ],
  }),
}

</script>

<style scoped>
.scan-info {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
</style>