
exports.urlWithQueryString = (url, object) => {
	let params = ''
	if (object) {
		let keys = Object.keys(object)
		params = keys.map( key => {
			let s = ''
			const value = object[key]
			if (typeof value === 'object') {
				s = JSON.stringify(value)
			} else {
				s = value
			}
			return `${key}=${encodeURIComponent(s)}` 
		}).filter(x => x.length > 0).join('&')
	}
	return (params?`${url}?${params}`:url)
}


let wsConfig = {
	wsURL: '',
	socket: undefined,
	started: false,
	callback: undefined,
}

function wsConnect() {
	//console.log(`Socket connecting... ${wsConfig.wsURL}`)
	let ws = new WebSocket( wsConfig.wsURL )
  wsConfig.socket = ws

	/*
  ws.onopen = function() {
		//console.log('Socket opened.');
    //ws.send(JSON.stringify({}));
  }
  */

  ws.onmessage = function(e) {
    //console.log('Socket Message:', e.data);
		let data = JSON.parse(e.data)
		if (wsConfig.callback) {
			wsConfig.callback(data)
		}
  }

  ws.onclose = function(e) {
    console.log('Socket closed.', e.reason)
		if (wsConfig.started) {
			setTimeout(function() {
				wsConnect();
			}, 1000);
		}
  }

  ws.onerror = function(err) {
    console.log('Socket error: ', err.message);
    ws.close();
  }
}




exports.openSocket = (url, cb) => {
	wsConfig.wsURL = url.replace('http','ws')
	wsConfig.started = true
	wsConfig.callback = cb
	wsConnect()
}


exports.closeSocket = () => {
	wsConfig.callback = undefined
	wsConfig.started = false
	if (wsConfig.socket) {
		wsConfig.socket.close()
		wsConfig.socket = undefined
	}
}

