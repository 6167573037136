<template>
  <div>
    <v-card class="rounded-lg elevation-12" rounded>
      <v-toolbar color="primary" height="56" dark flat>
        <h3>Social Accounts</h3>
      </v-toolbar>

      <v-card-text>
        <v-list two-line>
          <v-list-item v-for="item in items" :key="item.title">
            <v-list-item-avatar>
              <v-btn :color="item.iconColor" fab dark small>
                <v-icon dark v-text="item.icon"></v-icon>
              </v-btn>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-switch v-model="item.enabled" @click="doSwitchClick(item)"></v-switch>
            </v-list-item-action>

          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-row class="mx-0 py-2" align="center">
            <v-btn block rounded to="/settings">Back</v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
      
    </v-card>
  </div>
</template>

<script>
import Config from "@/config"
import Api from "@/lib/api"
import Lib from "@/lib"
import store from "@/store"

export default ({
  methods: {
    showNotNull(data) {
      let rst = 'none'
      if (data.Enabled) {
        if (data.UserName)
          rst = `${data.UserName}`
        else if (data.ID)
          rst = `${data.ID}`
      }
      return rst
    },
    getSocialInfo() {
      Api.SocialInfo().then((res)=>{
        let data = res.data
        if (data.errCode == 0) {
          this.info = data.SocialInfo

          this.items[0].enabled = this.info.Google.Enabled
          this.items[0].subtitle = this.showNotNull(this.info.Google)

          this.items[1].enabled = this.info.Facebook.Enabled
          this.items[1].subtitle = this.showNotNull(this.info.Facebook)

          this.items[2].enabled = this.info.Twitter.Enabled
          this.items[2].subtitle = this.showNotNull(this.info.Twitter)

          this.items[3].enabled = this.info.Telegram.Enabled
          this.items[3].subtitle = this.showNotNull(this.info.Telegram)

        } else {
          this.$dialog.notify.error(data.errMsg, Config.notifySetting)
        }
      }).catch(err=>{
        console.log(err)
      }) 
    },
    doSwitchClick(aItem) {
      //alert(`doSwitchClick( title=${aItem.title}, enabled=${aItem.enabled} )`)
      ~(async()=>{
        try {
          if (!aItem.enabled) {

            let ui = store.getters.UserInfo
            if ( ui.Provider == aItem.title ) {
              await this.$dialog.info({
                text: `Cannot remove the login provider you used to sign in to your current session`,
                title: 'Info'
              })
              aItem.enabled = true
              return
            }

            // unbind
            let res = await this.$dialog.confirm({
              text: `Are you sure you want to unlink ${aItem.title} acount?`,
              title: 'Confirm'
            })

            if (res) {
              let OpToken = store.getters.OpToken
              let {data} = await Api.SocialUnbind({
                OpToken,
                AccType: aItem.title,
              })
              if (data.errCode==0){
                this.getSocialInfo()
                return
              } else {
                this.$dialog.notify.error(data.errMsg, Config.notifySetting)
              }
            }
            aItem.enabled = true

          } else {
            // bind
            if (aItem.title!='Telegram') {
                window.location.href = Lib.urlWithQueryString('/auth/social/bind/',
                  { AccType: aItem.title,
                    NextPath:'/sociallinks' }
                )
            } else {
              let appInfo = store.getters.AppInfo
              window.Telegram.Login.auth(
                { bot_id: appInfo.tgBotID, request_access: true },
                (profile) => {
                  if (profile) {
                    let ExtInfo = JSON.stringify(profile)
                    window.location.href = Lib.urlWithQueryString('/auth/social/bind/',
                      { AccType:'Telegram', 
                        NextPath:'/sociallinks',
                        ExtInfo }
                    )
                  }
                }
              )
            }
          }
        } catch (err) {
          console.log(err)
        }
      })()
    },
  },
  data: ()=> ({
    info: {
      Google: {Enabled: false, ID: '', UserName: ''},
      Facebook: {Enabled: false, ID: '', UserName: ''},
      Twitter: {Enabled: false, ID: '', UserName: ''},
      Telegram: {Enabled: false, ID: '', UserName: ''},
    },
    items: [
      {
        icon: 'mdi-google',
        iconColor: "#dd4b39",
        iconClass: "grey lighten-1 white--text",
        title: 'Google',
        subtitle: 'dasdfdsf',
        enabled: false,
      },
      {
        icon: 'mdi-facebook',
        iconColor: "#3B5998",
        iconClass: "grey lighten-1 white--text",
        title: 'Facebook',
        subtitle: 'hrtrtf',
        enabled: false,
      },
      {
        icon: 'mdi-twitter',
        iconColor: "#1DA1F2",
        iconClass: "grey lighten-1 white--text",
        title: 'Twitter',
        subtitle: 'utyuusf',
        enabled: false,
      },
      {
        icon: 'mdi-telegram',
        iconColor: "#0088CC", // "#55ACEE",
        iconClass: "grey lighten-1 white--text",
        title: 'Telegram',
        subtitle: 'nnbndsf',
        enabled: false,
      },
    ],
  }),
  mounted() {
    this.getSocialInfo()
  },
})
</script>
