import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },  
  theme: {
    themes: {
      light: {
        primary: colors.cyan.darken1,
        background: colors.blue.darken2,
        
      },
      dark: {
        primary: colors.blue.lighten3,
        background: colors.blue.darken2,
      },
    },

  },
})

export default vuetify;
