<template>
  <div>
    <v-card class="rounded-lg elevation-12" rounded>
      <v-toolbar color="primary" height="56" dark flat>
        <h3>Profile Settings</h3>
      </v-toolbar>

      <v-card-text>
        <v-row class="mb-4">
          <v-col cols="3">
            <v-avatar size="96" class="mr-4">
              <v-img :src="pictureURL"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="7" class="d-flex align-end">
            <v-btn color="cyan darken-1" fab small dark>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            &nbsp;
            <v-btn color="cyan darken-1" :dark="!!info.Picture" :disabled="!info.Picture" fab small @click.prevent="doCleanAvatar()">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-form ref="Form" v-model="formSet.valid" lazy-validation>
          <v-text-field v-model="info.FirstName" light label="Firstname" :rules="[rules.required]"></v-text-field>
          <v-text-field v-model="info.LastName" light label="Lastname" :rules="[rules.required]"></v-text-field>
          <v-text-field v-model="info.NickName" light label="Nickname" :rules="[rules.min3]"></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-row class="mx-0 py-2" align="center">
            <v-btn color="success" block rounded type="submit" :disabled="!formSet.valid" @click.prevent="doSave">Save Changes</v-btn>
          </v-row>
          <v-row class="mx-0 py-2" align="center">
            <v-btn block rounded to="/settings">Cancel</v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <!--
    <avatar-picker v-model="showAvatarPicker" :current-avatar="form.avatar" @selected="selectAvatar"></avatar-picker>
    -->
  </div>
</template>

<script>

//import AvatarPicker from '~/components/AvatarPicker'
import Config from "@/config"
import Api from "@/lib/api"
import router from "@/router"
import store from "@/store"

export default {
  //components: { AvatarPicker },
  methods: {
    doSave() {
      ~(async () => {
        if (this.$refs.Form.validate()) {
          try {
            let OpToken = store.getters.OpToken
            let {data} = await Api.SetProfile({
              OpToken,
              NickName: this.info.NickName,
              FirstName: this.info.FirstName,
              LastName: this.info.LastName,
            })
            if (data.errCode == 0) {
              if (data.userInfo) {
                store.commit('setUserInfo', data.userInfo)
              }
              await this.$dialog.info({
                text: 'Save changes successfully.',
                title: 'Info'
              })
              router.replace('/settings')
            } else {
              this.$dialog.notify.error(data.errMsg, Config.notifySetting)
            }
          } catch (err) {
            console.log(err)
          }
        }
      })()
    },

    doCleanAvatar() {
      ~(async ()=>{
        let res = await this.$dialog.confirm({
          text: 'Are you sure you want to remove avatar?',
          title: 'Confirm'
        })
        
        if (res) {
          let OpToken = store.getters.OpToken
          let {data} = await Api.SetProfileAvatar({
            OpToken,
            Picture: undefined
          })
          if (data.errCode == 0) {
            this.info = data.userProfile
          } else {
            this.$dialog.notify.error(data.errMsg, Config.notifySetting)
          }
        }
      })()
      
    },
    doSelectAvatar() {
      this.showAvatarPicker = true
    },
    selectAvatar (avatar) {
      this.form.avatar = avatar
    },
  },
  computed: {
    pictureURL() {
      let imgurl = this.info.Picture ? this.info.Picture : require("@/assets/avatardefault.png")
      //console.log(`imgurl=${imgurl}`)
      return imgurl
    },
  },
  data: () => ({
    showAvatarPicker: false,
    formSet: {
      valid: true,
      showPass: false,
    },
    info: {
      FirstName: '',
      LastName: '',
      NickName: '',
      Picture: '',
    },        
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 6 || 'Min 6 characters',
      min3: v => v.length >= 3 || 'Min 3 characters',
    },
  }),

  mounted() {
    Api.GetProfile().then((res)=>{
      let data = res.data
      if (data.errCode == 0) {
        this.info = data.userProfile
      } else {
        this.$dialog.notify.error(data.errMsg, Config.notifySetting)
      }
    }).catch(err=>{
      console.log(err)
    }) 
  },
}
</script>