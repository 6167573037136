import Vue from 'vue'
import Router from 'vue-router'
import Config from "@/config"
import store from '../store'
import Lib from "@/lib"
import Api from "@/lib/api"

import Signup from '@/components/Signup'
import Login from '@/components/Login'
import Forgot from '@/components/Forgot'
import ShowMsg from '@/components/ShowMsg'
import ResetPass from '@/components/ResetPass'
import Settings from '@/components/Settings'
import Profile from '@/components/Profile'
import SocialLinks from '@/components/SocialLinks'
import DeviceVerification from '@/components/DeviceVerification'
import ChangePass from '@/components/ChangePass'



Vue.use(Router)

const routes = [
  {   
    path: '/', 
    component: Login,
    meta: {
      requireAuth: false,
      requireOpToken: false,
    },
  },
  { 
    path: '/login', 
    component: Login,
    meta: {
      requireAuth: false,
      requireOpToken: false,
    },
  },
  { 
    path: '/signup', 
    component: Signup,
    meta: {
      requireAuth: false,
      requireOpToken: false,
    },
  },
  {
    path: '/forgot',
    component: Forgot,
    meta: {
      requireAuth: false,
      requireOpToken: false,
    },
  },
  {
    path: '/showmsg',
    component: ShowMsg,
    meta: {
      requireAuth: false,
      requireOpToken: false,
    },
  },
  {
    path: '/resetpass',
    component: ResetPass,
    meta: {
      requireAuth: false,
      requireOpToken: false,
    },
  },
  {
    path: '/settings',
    component: Settings,
    meta: {
      requireAuth: true,
      requireOpToken: false,
    },
  },
  {
    path: '/profile',
    component: Profile,
    meta: {
      requireAuth: true,
      requireOpToken: true,
    },
  },
  {
    path: '/sociallinks',
    component: SocialLinks,
    meta: {
      requireAuth: true,
      requireOpToken: true,
    },
  },
  {
    path: '/deviceverification',
    component: DeviceVerification,
    meta: {
      requireAuth: true,
      requireOpToken: true,
    },
  },
  {
    path: '/changepass',
    component: ChangePass,
    meta: {
      requireAuth: true,
      requireOpToken: true,
    },
  },
]

const router = new Router({
  routes
  // routes: routes,
  // mode: 'history'
})


async function processCallback(data) {
  let nextPath = undefined

  store.commit('setVerified', true)
  if (data.returnurl) store.commit('setReturnURL', data.returnurl)
  if (data.userInfo) store.commit('setUserInfo', data.userInfo)
  if (data.ResetToken) store.commit('setResetToken', data.ResetToken)
  if (data.OpToken) store.commit('setOpToken', data.OpToken)

  if (data.NextPath) 
    nextPath = data.NextPath
  else
    nextPath = undefined

  if (data.uictrl) {
    if (data.uictrl == 'msg') {
      store.commit('setLastMsg', {errCode: data.errCode, errMsg: data.errMsg})
      nextPath = '/showmsg/'
    } else if (data.uictrl == 'repw') {
      nextPath = '/resetpass/'
    }
  }

  if (!nextPath) {
    if (store.getters.isAuthenticated) {
      nextPath = {path:'/settings', replace:true}
    } else {
      store.dispatch('Logout')
      nextPath ='/login'
    }
  }

  if (data.errCode && data.errCode != -1) {
    setTimeout(()=>{
      router.app.$dialog.notify.error(data.errMsg, Config.notifySetting)
    }, 800)
  }

  //console.log(`processCallback next=${JSON.stringify(nextPath)}, data=${JSON.stringify(data)}`)
  return nextPath
}



async function checkAuth(path, requireAuth, requireOpToken) {
  let nextPath = undefined
  let isVerified = store.getters.isVerified
  let isAuthenticated = store.getters.isAuthenticated

  if (isVerified) {
    if (isAuthenticated) {
      if (path=='/' || path=='/login') {
        nextPath ={path:'/settings', replace:true}
      } else {
        if (requireOpToken) {
          //let info = store.getters.UserInfo
          let token = store.getters.OpToken
          
          if (token) {
            nextPath = undefined
          } else {
            nextPath = false
            let {data} = await Api.PermissionOpToken()

            if (data.errCode == 0) {
              store.commit('setOpToken', data.OpToken)
              nextPath = undefined
            } else if (data.errCode == -11) {
              let pass = await router.app.$dialog.prompt({
                title: 'Authorization',
                text: 'Enter your password',
                textField: {
                  type: 'password',
                }
              })
              if (pass) {
                let {data} = await Api.PermissionOpToken({Password: pass})
                if (data.errCode == 0) {
                  store.commit('setOpToken', data.OpToken)
                  nextPath = undefined
                } else {
                  router.app.$dialog.notify.error(data.errMsg, Config.notifySetting)
                }
              }
            } else if (data.errCode == -17) {
              let ui = store.getters.UserInfo
              if (ui.Provider=='Telegram') {
                let appInfo = store.getters.AppInfo
                window.Telegram.Login.auth(
                  { bot_id: appInfo.tgBotID, request_access: true },
                  (profile) => {
                    if (profile) {
                      let ExtInfo = JSON.stringify(profile)
                      let url = Lib.urlWithQueryString(data.RedirURL,
                        { NextPath: path,
                          ExtInfo }
                      )
                      window.location.href = url
                    }
                  }
                )
              } else {
                window.location.href = Lib.urlWithQueryString(data.RedirURL,
                  { NextPath: path }
                )
              }
              nextPath = false
            } else {
              router.app.$dialog.notify.error(data.errMsg, Config.notifySetting)
            }

          }
        }
      }
    } else {
      if (requireAuth || requireOpToken) nextPath = '/login'
    }
  } else {
    nextPath = 'vertify'    
  }

  //console.log(`checkAuth path=${path}, next=${nextPath}, requireAuth=${requireAuth}, requireOpToken=${requireOpToken}`)
  return nextPath
}


router.beforeEach(async(to, from, next) => {
  let requireAuth = to.matched.some(record => record.meta.requireAuth)
  let requireOpToken = to.matched.some(record => record.meta.requireOpToken)

  //console.log(`router.beforeEach ${to.path}, requireAuth=${requireAuth}, requireOpToken=${requireOpToken}`)

  let nextPath
  if (to.path === '/callback/') {
    let s = to.query.data
    if (s) {
      let data = JSON.parse(s)
      nextPath = await processCallback(data)
    }
  } else {
    nextPath = await checkAuth(to.path, requireAuth, requireOpToken)
  }
  if (nextPath == 'vertify') {
    window.location.href = '/auth/verify'
  } else {
    next(nextPath)
  }
})



export default router
