<template>
  <div>
    <v-card class="rounded-lg elevation-12" rounded>
      <v-toolbar color="primary" height="56" dark flat>
        <h3>Create a New Account</h3>
      </v-toolbar>

      <v-card-text>
        <v-form ref="Form" v-model="formSet.valid" lazy-validation>
          <v-text-field v-model="info.firstname" light prepend-icon="mdi-account" label="Firstname" :rules="[rules.required]"></v-text-field>
          <v-text-field v-model="info.lastname" light prepend-icon="mdi-account" label="Lastname" :rules="[rules.required]"></v-text-field>
          <v-text-field v-model="info.nickname" light prepend-icon="mdi-account" label="Nickname"></v-text-field>
          <v-text-field v-model="info.username" light prepend-icon="mdi-email" label="Email" type="email" :rules="emailRules" required></v-text-field>
          <v-text-field v-model="info.password" light prepend-icon="mdi-lock" label="Password" :type="formSet.showPass ? 'text' : 'password'" :append-icon="formSet.showPass ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" hint="At least 6 characters" counter @click:append="formSet.showPass = !formSet.showPass"></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-row class="mx-0" align="center">
            <v-btn color="success" block rounded type="submit" :disabled="!formSet.valid" @click.prevent="doSignup">Sign up</v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>

    <v-content class="my-4">
      <v-row class="my-1" align="center" justify="center">
        <span class="wl">
          <h4>
            <router-link to="/login" tag="wl">Already have an account?</router-link>
          </h4>
        </span>
      </v-row>
    </v-content>

  </div>
</template>

<style scoped>
  .wl, wl {
    color: #ffffff;
    cursor: pointer;
  }

  wl:hover {
    text-decoration: underline;
  }
</style>

<script>

import Config from "@/config"
import Api from "@/lib/api"
import Lib from "@/lib"
import router from "@/router"
import store from "@/store"

export default {
  methods: {
    doSignup() {
      ~(async ()=>{
        if (this.$refs.Form.validate()) {
          try {
            let {data} = await Api.Signup({
              FirstName: this.info.firstname,
              LastName: this.info.lastname,
              NickName: this.info.nickname,
              UserName: this.info.username, 
              Password: this.info.password,
            })

            if (data.errCode == 0) {
              await this.$dialog.info({
                text: 'Congratulations, your account has been successfully created.',
                title: 'Info'
              })
              if (data.userInfo) {
                store.commit('setUserInfo', data.userInfo)
              }
              let ReturnURL = store.getters.ReturnURL
              if (ReturnURL) {
                window.location.href = Lib.urlWithQueryString(ReturnURL,{data})
              } else {
                router.replace('/settings')
              }

/*
ToDo: Verify email address

Your account has been made.
Please verify it by clicking the activation link that has been send to your email.
------------------------------------------------------
You're nearly there!

We just need to verify your email address to complete your xxx signup.
[Verify email address]

Please note that this link will expire in 5 days.

If you have not signed up to XXX, please ignore this email.

Thanks
*/

             


            } else {
              this.$dialog.notify.error(data.errMsg, Config.notifySetting)
            }
          } catch (err) {
            console.log(err)
          }
        }
      })()
    }
  },
  data: () => ({
    formSet: {
      valid: true,
      showPass: false,
    },
    info: {
      firstname: "",
      lastname: "",
      nickname: "",
      username: "",
      password: "",
    },        
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 6 || 'Min 6 characters',
    },
  }),
}
</script>