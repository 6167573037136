import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import router from '@/router'
import Vidle from 'v-idle'



Vue.config.productionTip = false

Vue.use(Vidle)

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
